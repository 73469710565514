import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { InsightsDefaultCard } from '@/components/cards/InsightsDefaultCard';
import useArtist from '@/hooks/artist/useArtist';
import { InsightsTabs } from '@/models/Enums';

import MusicInsights from '../components/insights-page/Music/MusicInsights';
import SocialsInsights from '../components/insights-page/Socials/SocialsInsights';
import UserHeader from '../components/utility/navigation/UserHeader';

const InsightsPage = () => {
  const [params, setSearchParams] = useSearchParams();
  const { artist } = useArtist();
  const tab = params.get('tab');
  const platform = params.get('platform');

  useEffect(() => {
    if (tab && platform && Object.values(InsightsTabs).includes(tab as InsightsTabs)) {
      params.set('tab', tab);
      params.set('platform', platform);
      setSearchParams(params, { replace: false });
    } else {
      params.set('tab', InsightsTabs.SOCIALS);
      setSearchParams(params, { replace: false });
    }
  }, [params, platform, setSearchParams, tab]);

  return (
    <div data-testid="insights-page" className="page-content">
      <UserHeader title="PAGE-TITLES.DATA-AND-INSIGHTS" isInsights={artist ? true : false} />
      {artist && (
        <>
          {tab === InsightsTabs.SOCIALS && <SocialsInsights />}
          {tab === InsightsTabs.MUSIC && <MusicInsights />}
        </>
      )}
      {!artist && (
        <div className="mt10">
          <InsightsDefaultCard />
        </div>
      )}
    </div>
  );
};

export default InsightsPage;
